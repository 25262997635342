import React, { type ReactNode, type FC, useState, useEffect, useMemo } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

import { AssetsOptions } from '../../../../../pages/Savings/Tables/AssetsOptions/AssetsOptions';
import { CurrencySelect, ButtonComponent, AssetAmountInputComponent, TimelineProgressComponent } from '../../../../../components';
import { type SavingOption, type Saving } from '../../../../../interfaces/SavingInterface';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/redux';
import { calculateReleaseTime, formatDate, toFixed } from '../../../../../helpers/util';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { getCurrencyRates } from '../../../../../redux/reducers/currencyRates';

import style from './Step1SectionModalAssetsSubscribe.module.css';

interface Step1SectionModalAssetsSubscribeInterface {
  icon: ReactNode
  saving: Saving
  open: boolean
  reqData: {
    selectedAsset?: string
    currencyId: string
    releaseTime?: Date
    investedAmount?: number
    userId?: string
    selectedOption?: SavingOption
  }
  handleChangeReqData: ({ field, value }: { field: string, value: any }) => void
}

export const Step1SectionModalAssetsSubscribe: FC<Step1SectionModalAssetsSubscribeInterface> = ({ icon, saving, reqData, handleChangeReqData, open }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accounts = useAppSelector((state) => state.accounts);
  const { rates } = useAppSelector(state => state.currencyRates);
  const [widthScreen] = useWindowSize();

  const isMobile = (widthScreen <= 905);
  const { selectedAsset } = reqData;
  const [currency, setCurrency] = useState<string>((accounts.length > 0) ? accounts[0]._id : '');
  const [value, setValue] = useState<string>('');
  const [selectedOption] = saving.options.filter((item) => item._id === selectedAsset);

  const account = useMemo(() => accounts.find((item) => item._id === currency), [accounts, currency]);
  const subscriptionDate = new Date();
  const [releaseTime, setReleaseTime] = useState<Date>(subscriptionDate);

  const handleChangeOption = (value: string): void => {
    handleChangeReqData({ field: 'selectedAsset', value })
  }

  useEffect(() => {
    if (account?.cfdAccountCurrency !== undefined) {
      dispatch(getCurrencyRates(account.cfdAccountCurrency.symbol))
    }
  }, [account])

  useEffect(() => {
    if (!selectedOption.isFlexible) {
      setReleaseTime(calculateReleaseTime({ subscriptionDate, option: selectedOption }))
    }
  }, [selectedOption])

  useEffect(() => {
    handleChangeReqData({ field: 'selectedOption', value: selectedOption })
  }, [selectedAsset])

  useEffect(() => {
    handleChangeReqData({ field: 'releaseTime', value: releaseTime })
  }, [releaseTime]);

  useEffect(() => {
    handleChangeReqData({ field: 'investedAmount', value })
  }, [value])

  useEffect(() => {
    if (currency.length > 0) {
      handleChangeReqData({ field: 'cfdAccountId', value: currency });
      setValue('');
    }
  }, [currency, open])

  const calcRate = (value: number): number => {
    if (rates !== undefined && saving !== undefined) {
      const rate = rates[saving.currency.symbol];

      if (rate === undefined) return 0;

      return toFixed(value / rate, 3);
    }

    return 0;
  }

  const calcRateToSavingAsset = (value: number): number => {
    if (rates !== undefined && saving !== undefined) {
      const rate = rates[saving.currency.symbol];

      if (rate === undefined) return 0;

      return value * rate;
    }

    return 0;
  }

  return (
    <section className={ style.sectionWrapper }>
      <div className={ style.titleWrapper }>
        { icon }
        <h3 className={ style.titleText }>{saving?.currency?.symbol}</h3>
      </div>
      <div className={ style.selectOptions }>
        <p className={ style.selectOptionsText }>{t('savings_page.subscribe_modal.Durations')}:</p>
        <div className={ style.selectOptionsAssetsWrapper }>
          <AssetsOptions options={saving?.options} selectedAsset={selectedAsset ?? ''} handleChange={handleChangeOption} />
        </div>
        <div className={ style.selectWalletWrapper }>
          <div className={ style.selectWalletContainer }>
            <div className={ style.selectWallet }>
              <p className={ style.selectWalletText }>{t('savings_page.subscribe_modal.Account_for_payment')}</p>
              <div className={ style.wallet }>
                <DoneOutlinedIcon fontSize='small' sx={{ color: 'var(--CFD-theme-System-OnSurfaceVariant)' }}/>
                <CurrencySelect currency={ currency } setCurrency={ setCurrency } accounts={accounts}/>
              </div>
            </div>
            <div className={ style.balance }>
              <p className={ style.balanceText }>{t('labels.balance')}</p>
              <p className={ style.balanceAmount }>{toFixed(account?.balance ?? 0, 2)} {account?.cfdAccountCurrency?.symbol}</p>
            </div>
          </div>
          {account !== undefined && account.balance < saving.minDeposit && (
            <div className={ style.selectWalletInfoWrapper }>
            <div className={ style.selectWalletInfoContent }>
              <InfoOutlinedIcon sx={{ color: 'var(--CFD-theme-System-Inverseonsurface)' }}/>
              <p className={ style.selectWalletInfoContentText }>
              {t('savings_page.subscribe_modal.Info_text')}
              </p>
            </div>
            <div className={ style.selectWalletInfoBtnWrapper }>
              <div className={ style.selectWalletInfoBtn }>
                <ButtonComponent variant='text'>
                  <span className={ style.btnText }>{t('savings_page.subscribe_modal.Top_up_account_balance')}</span>
                </ButtonComponent>
              </div>
            </div>
          </div>
          )}
        </div>
        <div className={ style.subscriptionAmountWrapper }>
          <label className={ style.subscriptionAmountTitle } htmlFor='assetAmount'>{t('savings_page.subscribe_modal.Subscription_amount')}</label>
          <AssetAmountInputComponent
            id='assetAmount'
            value={ value }
            setValue={ setValue }
            icon={ icon }
            currency={saving?.currency.symbol}
            placeholder='0.0'
            maxValue={String(calcRateToSavingAsset(toFixed(account?.balance ?? 0, 2)))}
          />
          <p className={ style.subscriptionAmountMin }>
            {t('savings_page.subscribe_modal.Minimum')}:
            <span className={ style.minMaxAmount }>{saving?.minDeposit}</span>
          </p>
          <p className={ style.subscriptionAmountMax }>
            {t('savings_page.subscribe_modal.Max')}:
            <span className={ style.minMaxAmount }>1000000</span>
          </p>
        </div>
        <div className={ style.subscriptionCurrencyConverterInfo }>
          <div className={ style.subscriptionCurrencyConverterInfoRow }>
            <span>{t('savings_page.converter.investment')}</span>
            <span>{value ?? 0} {saving?.currency.symbol}</span>
          </div>
          <div className={ style.subscriptionCurrencyConverterInfoRow }>
            <span>{t('savings_page.converter.converted')}</span>
            <span>{calcRate(Number(value))} {account?.cfdAccountCurrency?.symbol}</span>
          </div>
          <div className={ style.subscriptionCurrencyConverterInfoRow }>
            <span>{t('labels.exchange_rate')}</span>
            <span>1 {saving?.currency.symbol} = {calcRate(1)} {account?.cfdAccountCurrency?.symbol}</span>
          </div>
        </div>
      </div>
      {!selectedOption.isFlexible
        ? <div className={ style.summaryContainer }>
        <p className={ style.summaryContainerText }>{t('savings_page.subscribe_modal.Summary')}</p>
        <TimelineProgressComponent
          heigth={ isMobile ? 30 : 40 }
          amountOfTimelines={ 1 }
          percentageOfFill={ 100 }
          paddingBetweenTimeline={ 20 }
          timeLines={ [
            { label: `${t('savings_page.subscribe_modal.Subscription_date')}`, value: formatDate(subscriptionDate) },
            { label: `${t('savings_page.subscribe_modal.Release_date')}`, value: formatDate(releaseTime) }
          ] }
        />
        <div className={ style.summaryAmountContainer }>
          <span className={ style.summaryAmountTitle }>{t('savings_page.subscribe_modal.You_will_receive')}</span>
          <span className={ style.summaryAmount }>{ `${Number(value) + (Number(value) * (selectedOption.percent / 100))} ${saving?.currency.symbol}` }</span>
        </div>
      </div>
        : <div className={ style.emptySummaryContainer } />}
    </section>
  )
}
