import React, { type FC } from 'react';

export const DefaultLogo: FC = () => {
  const fillColor = 'var(--CFD-theme-System-OnSurface)';

  return (
<svg width="160" height="40" viewBox="0 0 160 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M19.0458 30.3041L6.85457 8L0 8.0561L15.0008 32.5514L19.6635 32.4953L31.4621 8.0561L19.0458 30.3041Z" fill="#A7CA12"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.2165 8.18216L19.095 20.1414L25.9451 8.09769H20.611L17.1011 14.4146L12.2165 8.18216Z" fill="#A7CA12"/>
<path d="M43.1584 25.7825H40.7031C40.1421 25.7825 39.6157 25.432 39.4052 24.9057L34.8628 13.5056H37.5986L41.457 23.2043L44.9997 14.4001C45.2102 13.8737 45.7366 13.5056 46.2976 13.5056H48.1042L43.1584 25.7825Z" fill={fillColor}/>
<path d="M52.4714 25.7826H49.9285V13.5063H51.5946C52.068 13.5063 52.4714 13.9097 52.4714 14.3831V25.7826Z" fill={fillColor}/>
<path d="M54.5762 25.7825L63.2399 14.0666C63.5028 13.6985 64.0115 13.5056 64.4679 13.5056H66.8179L58.1535 25.2215C57.8906 25.5896 57.3643 25.7825 56.9085 25.7825H54.5762ZM58.6452 18.8195L54.5762 13.5056H57.786L60.2066 16.6977L58.6452 18.8195ZM66.8179 25.7825H63.6963L61.2934 22.4681L62.8894 20.311L66.8179 25.7825Z" fill={fillColor}/>
<path d="M75.2187 15.9961L71.3426 25.7825H68.6068L73.4827 13.5056H75.9909C76.5519 13.5056 77.0783 13.8561 77.2888 14.3824L81.7613 25.7825H79.0255L75.2187 15.9961Z" fill={fillColor}/>
<path d="M82.287 19.6794C82.287 16.0138 85.2509 13.3303 88.8289 13.3303C90.7408 13.3303 92.6697 14.1372 93.9153 15.6104C93.4942 15.9085 92.4774 16.7501 91.9857 16.7501C91.4594 16.7501 90.5649 15.6627 88.8289 15.6627C86.5841 15.6627 84.9004 17.434 84.9004 19.6787C84.9004 21.9235 86.6541 23.6425 88.8995 23.6425C90.5832 23.6425 91.4777 22.6074 91.9864 22.6074C92.4951 22.6074 93.4419 23.4319 93.863 23.7301C92.6527 25.1509 90.7061 25.9748 88.8295 25.9748C85.2516 25.9748 82.287 23.3449 82.287 19.6794Z" fill={fillColor}/>
<path d="M101.702 15.9961L97.826 25.7825H95.0902L99.9661 13.5056H102.474C103.035 13.5056 103.562 13.8561 103.772 14.3824L108.245 25.7825H105.509L101.702 15.9961Z" fill={fillColor}/>
<path d="M112.331 25.7825H109.788V19.0477H115.733C116.768 19.0477 117.522 18.4161 117.522 17.3817C117.522 16.3472 116.751 15.698 115.733 15.698H110.665C110.191 15.698 109.788 15.2945 109.788 14.8211V13.5056H115.962C118.242 13.5056 120.066 14.9964 120.066 17.364C120.066 18.4161 119.698 19.3635 118.926 20.0821C118.137 20.8361 117.067 21.1519 115.962 21.1519H112.332V25.7819L112.331 25.7825Z" fill={fillColor}/>
<path d="M124.134 25.7826H121.591V13.5063H123.257C123.731 13.5063 124.134 13.9097 124.134 14.3831V25.7826Z" fill={fillColor}/>
<path d="M132.482 25.7826H129.939V15.751H125.8V13.5063H137.183L136.516 15.19C136.394 15.5234 136.061 15.751 135.71 15.751H132.483L132.482 25.7826Z" fill={fillColor}/>
<path d="M142.357 15.9961L138.481 25.7825H135.745L140.621 13.5056H143.129C143.69 13.5056 144.216 13.8561 144.427 14.3824L148.899 25.7825H146.164L142.357 15.9961Z" fill={fillColor}/>
<path d="M150.74 25.7826V13.5063H152.406C152.879 13.5063 153.283 13.9097 153.283 14.3831V23.5385H160L159.334 25.2222C159.211 25.5557 158.878 25.7832 158.527 25.7832L150.74 25.7826Z" fill={fillColor}/>
<path fillRule="evenodd" clipRule="evenodd" d="M98.8977 19.1763L101.613 23.8984L104.318 19.1429H102.212L100.826 21.6373L98.8977 19.1763Z" fill="#A7CA12"/>
<path fillRule="evenodd" clipRule="evenodd" d="M72.5642 19.1763L75.2798 23.8984L77.9847 19.1429H75.8781L74.4925 21.6373L72.5642 19.1763Z" fill="#A7CA12"/>
<path fillRule="evenodd" clipRule="evenodd" d="M139.495 19.1763L142.211 23.8984L144.915 19.1429H142.809L141.424 21.6373L139.495 19.1763Z" fill="#A7CA12"/>
</svg>

  )
}
